<template>
  <!-- crear nueva asamblea -->
  <div v-if="checkPermission(['CREATE_ASSEMBLY', 'ALLOW_ALL']) && (actions.name == 'create' || actions.name == 'edit')">
    <create-assembly
      :actions="actions"
      @change-type-view="assignView('list')"
    />
  </div>
  <!--/ crear nueva asamblea -->

  <!-- información de asambleas -->
  <b-row v-else>
    <template v-if="currentAssembly">
      <!-- detalle de asamblea -->
      <b-col cols="12">
        <b-overlay
          :show="applyOverlayAssembly"
          :class="applyOverlayAssembly ? 'p-2 mb-1' : 'mb-1'"
        >
          <template #overlay>
            <div class="d-flex align-items-center justify-content-center mt-1">
              <b-spinner
                type="border"
                variant="primary"
              />
            </div>
            <div class="d-flex justify-content-center">
              <p class="pt-1">
                {{ $t('loading') }}
              </p>
            </div>
          </template>
          <detail-component
            :apply-overlay="applyOverlayAssembly"
            :actions="actions"
            :n-assemblies="paginate.total"
            :check-permission="checkPermission"
            @stop-overlay="applyOverlayAssembly = false"
            @edit-assembly="editAssembly"
            @delete-assembly="confirmDelete"
          />
        </b-overlay>
      </b-col>
      <!--/ detalle de asamblea -->

      <!-- historico de asambleas -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.4)'"
          v-b-toggle.collapse-1
          pill
          block
          variant="outline-primary"
        >
          {{ $t('generic.historicalData') }}
        </b-button>
        <b-collapse
          id="collapse-1"
          class="mt-2"
        >
          <b-row v-if="checkPermission(['CREATE_ASSEMBLY', 'ALLOW_ALL'])">
            <b-col cols="12 mb-1">
              <span class="float-right">
                {{ $t( 'clubs.assemblies.addAssembly') }}
                <b-button
                  class="ml-1 btn-icon rounded-circle"
                  variant="primary"
                  :style="colorPrimaryBtn"
                  @click="assignView('create')"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-table
                ref="historyRefAss"
                hover
                class="position-relative i-min-height-table"
                :items="assemblies"
                :fields="assemblyFields"
                :no-border-collapse="true"
                responsive
                primary-key="id"
                show-empty
                :empty-text="$t('generic.noRecordsFound')"
              >
                <template #cell(incorporation_date)="row">
                  <span> {{ row.item.incorporation_date | formatDate() }} </span>
                </template>
                <template #cell(show_details)="row">
                  <b-form-checkbox
                    v-model="row.detailsShowing"
                    class="i-check-hide-co"
                    @change="row.toggleDetails"
                    @input="assignDetailAssembly(row.item.hash, row.detailsShowing, row.index)"
                  >
                    <span
                      class="i-text-details-so"
                      :class="{'text-primary' : row.detailsShowing }"
                    >
                      {{ $t('buttons.details') }}
                      <feather-icon
                        icon="ArrowDownIcon"
                        :class="row.detailsShowing ? 'i-icon-arrow-down' : 'i-icon-arrow-up'"
                      />
                    </span>
                  </b-form-checkbox>
                </template>
                <template #row-details="row">
                  <div v-if="actionsTable.showDetail">
                    <detail-in-table
                      :actions="actionsTable"
                      :n-assemblies="paginate.total"
                      :check-permission="checkPermission"
                      @edit-assembly="editAssembly"
                      @delete-assembly="confirmDelete"
                    />
                  </div>
                </template>
              </b-table>
              <app-paginator
                :data-list="paginate"
                @pagination-data="changePaginate"
              />
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>
      <!--/ historico de asambleas -->
    </template>

    <!-- Vista principal registro -->
    <b-col
      v-else
      cols="12"
      class="i-min-height-register-so"
    >
      <first-view
        :text-one="$t('clubs.assemblies.registerAssembly')"
        :text-two="$t('clubs.assemblies.continueAssembly')"
        :text-in-button="$t('clubs.assemblies.newAssembly')"
        :color-primary-btn="colorPrimaryBtn"
        @show-register-component="assignView"
      />
    </b-col>
    <!--/ Vista principal registro -->

    <b-col md="12 mt-1">
      <hr>
      <b-button
        class="float-right ml-1"
        variant="secondary"
        pill
        @click="$router.push({ name: 'clubs' })"
      >
        {{ $t('buttons.back') }}
      </b-button>
    </b-col>
  </b-row>
  <!--/ información asambleas -->
</template>

<script>
/* eslint-disable object-curly-newline */
import { $themeColors } from '@themeConfig'
import { mapGetters, mapActions } from 'vuex'
import { BRow, BCol, BTable, BButton, BCollapse, VBToggle, BOverlay, BFormCheckbox } from 'bootstrap-vue'
import checkPermission from '@/auth/permissions'
import assemblyService from '@/services/assemblyService'
import Ripple from 'vue-ripple-directive'
import AppPaginator from '@/views/components/app-paginator/AppPaginator.vue'
import CreateAssembly from './Create.vue'
import DetailComponent from './Detail.vue'
import DetailInTable from './DetailInTable.vue'
import FirstView from '../FirstView.vue'

export default {
  components: {
    AppPaginator,
    CreateAssembly,
    DetailComponent,
    DetailInTable,
    FirstView,
    BOverlay,
    BRow,
    BCol,
    BTable,
    BButton,
    BCollapse,
    BFormCheckbox,
  },

  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },

  data() {
    return {
      assemblies: [],
      currentAssembly: null,
      applyOverlayAssembly: false,
      actions: {
        clubId: null,
        assemblyId: null,
        name: '',
      },
      actionsTable: {
        clubId: null,
        assemblyId: null,
        showDetail: false,
      },
      filters: {
        club_hash: null,
        instrument_number_hash: null,
        created_at: null,
        is_current: false,
      },
      paginate: {
        from: 1,
        to: 15,
        total: 0,
      },
    }
  },

  mounted() {
    if (this.$route.params.id === 'undefined' && this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
      this.$router.push({ name: 'clubs' })
      return
    }
    if (this.$route.params.id === 'undefined' && !this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
      this.$router.push({ path: `/clubs/${this.clubInfo.hash}/assemblies` })
    }
    this.filters.club_hash = this.$route.params.id
    this.actions.clubId = this.$route.params.id
    this.actions.name = 'list'

    this.fetchAssemblies()
  },

  computed: {
    ...mapGetters({
      colorPrimaryBtn: 'colorPrimaryBtn',
      clubInfo: 'clubInfo',
    }),

    assemblyFields() {
      return [
        {
          key: 'instrument_number.notary_name',
          label: this.$t('clubs.instrumentNumber.labels.notaryName'),
          sortable: true,
        },
        {
          key: 'instrument_number.instrument_number',
          label: this.$t('clubs.instrumentNumber.labels.instrumentNumber'),
          sortable: true,
        },
        {
          key: 'incorporation_date',
          label: this.$t('clubs.society.labels.incorporationDate'),
          sortable: true,
        },
        {
          key: 'show_details',
          label: '',
          sortable: false,
        },
      ]
    },
  },

  methods: {
    checkPermission,
    assignView(pTypeView) {
      if (!this.checkPermission(['CREATE_ASSEMBLY'])) {
        this.showError('You do not have the necessary permissions to perform this action.')
      } else {
        this.actions.assemblyId = null
        this.actions.name = pTypeView

        if (pTypeView == 'list') {
          this.fetchAssemblies()
          this.returnTop()
        }
      }
    },

    fetchAssemblies() {
      this.applyOverlayAssembly = true

      const params = {
        included: 'club,instrumentNumber',
        perPage: this.paginate.to,
        page: this.paginate.from,
      }

      assemblyService.getAssemblies(params, this.filters).then(({ data }) => {
        this.paginate.total = data.total

        if (data.total > 0) {
          this.currentAssembly = data.data.filter(item => item.is_current)
          this.assemblies = data.data.filter(item => !item.is_current)

          setTimeout(() => { this.actions.assemblyId = this.currentAssembly[0].hash }, 300)
        } else this.applyOverlayAssembly = false
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    editAssembly(pAssembly) {
      this.actions.assemblyId = pAssembly
      this.actions.name = 'edit'
    },

    deleteAssembly(pAssemblyIdX) {
      const assemblyParamX = {
        clubId: this.$route.params.id,
        id: pAssemblyIdX,
      }
      assemblyService.deleteAssembly(assemblyParamX)
    },

    changePaginate(paginateData) {
      this.paginate.to = paginateData.perPage
      this.paginate.from = paginateData.page
      this.fetchAssemblies()
    },

    assignDetailAssembly(pAssemblyId, pCheckboxValue, pIndex) {
      if (pCheckboxValue) {
        this.actionsTable.showDetail = false

        for (let i = 0; i <= this.$refs.historyRefAss.localItems.length - 1; i++) {
          if (i != pIndex) {
            if (this.$refs.historyRefAss.localItems[i]._showDetails != undefined) {
              this.$refs.historyRefAss.localItems[i]._showDetails = false
            }
          }
        }

        this.actionsTable.showDetail = true
        this.actionsTable.clubId = parseInt(this.$route.params.id, 10)
        this.actionsTable.assemblyId = pAssemblyId
      }
    },

    returnTop() {
      const rootEle = document.documentElement

      rootEle.scrollTo({
        top: 50,
        behavior: 'smooth',
      })
    },

    async confirmDeleteAssembly() {
      const res = await this.$swal({
        title: this.$t('are-you-sure'),
        text: this.$t('you-will-not-be-able-to-reverse-this'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('buttons.cancel'),
        confirmButtonText: this.$t('continue'),
        confirmButtonColor: this.colorRGB,
        cancelButtonColor: $themeColors.secondary,
        customClass: {
          cancelButton: 'ml-1',
        },
      })
      return res
    },

    confirmDelete(pAssemblyIdX) {
      this.confirmDeleteAssembly().then(result => {
        if (result.isConfirmed) {
            this.deleteAssembly(pAssemblyIdX)
            this.responseSuccessElimination()
            this.assignView('list')
        }
      })
    }

  },
}
</script>

<style scoped>
.i-text-details-so {
  font-size: 12px;
  color: #33334d;
  visibility: visible;
  cursor: pointer;
}

.i-text-details-so:hover {
  color: #6abeff;
}

.i-min-height-register-so {
  min-height:300px;
}

.i-check-hide-co {
  visibility: hidden;
}

.i-icon-arrow-up {
  padding-bottom: 2px;
}

.i-icon-arrow-down {
  padding-top: 2px;
  transform: rotate(180deg);
}
</style>
