import queryParams from '@/services/queryParams'
import apiInstance from './index'

export default {
  async getAssemblies(paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`assemblies${query}`)
  },

  async storeAssembly(data) {
    return await apiInstance.post('assemblies', data)
  },

  async getAssemblyInfo(id) {
    return await apiInstance.get(`assemblies/${id}`)
  },

  async updateAssembly(data) {
    return await apiInstance.put(`assemblies/${data.id}`, data)
  },

  async deleteAssembly(data) {
    return await apiInstance.delete(`assemblies/${data.id}`)
  },
}
